import React, { Component } from 'react'

export class MeusAnimais extends Component {
	render() {
		return (
			<div>
				Meus animais
			</div>
		)
	}
}

export default MeusAnimais
