import { verificarValidadeDeCampo } from "../helpers/validacoes";
import { VscClose } from 'react-icons/vsc';

export function xDeFechar(diretorioClique) {
	return (
		<div className='container-x-de-fechar '>
			<div className='x-de-fechar' onClick={
				() => {
					if (verificarValidadeDeCampo(diretorioClique)) {
						window.location.assign(diretorioClique)
					}
				}
			}>
				<VscClose/>
			</div>
		</div>
	)
}

