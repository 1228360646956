import { firebaseApp } from "../cache/firebase";
import { getAuth } from "firebase/auth";
import { verificarValidadeDeCampo } from "./validacoes";
import { dataDepoisDeAgora } from './date';
import { keyEmailUsuario, keyUidlUsuario, keyPermissao, keyExpiracaoTokenLoginUsuario, keyInfoUsuarioFoto } from './keys';
import { pegarDocumentoFirestore } from "../functions/firestore";
import { triagemDeErrosDoServidor } from "./erros";
import { codificarPermissaoDeTela, verificarSePermissaoPodeSerValida } from "./permission";

const auth = getAuth(firebaseApp);

export function loga(user, permissao, direciona) {
	sessionStorage.setItem(keyEmailUsuario, user.email)
	sessionStorage.setItem(keyUidlUsuario, user.uid)
	sessionStorage.setItem(keyPermissao, codificarPermissaoDeTela(permissao))
	sessionStorage.setItem(keyExpiracaoTokenLoginUsuario, user.stsTokenManager.expirationTime)

	if (verificarValidadeDeCampo(direciona)) {
		window.location.assign(direciona);
	} else {
		window.location.assign('/home');
	}
}

export function seLogado() {
	try {
		const dataSalvaParaExpiracao = sessionStorage.getItem(keyExpiracaoTokenLoginUsuario)
		const tokenValido = dataDepoisDeAgora(dataSalvaParaExpiracao)

		return verificarValidadeDeCampo(sessionStorage.getItem(keyEmailUsuario))
			&& verificarValidadeDeCampo(sessionStorage.getItem(keyUidlUsuario)
				&& verificarSePermissaoPodeSerValida(sessionStorage.getItem(keyPermissao))
				&& verificarValidadeDeCampo(dataSalvaParaExpiracao)
				&& tokenValido);
	} catch (error) {
		return false
	}
}

export function usuarioLogado() {
	const user = {
		email: sessionStorage.getItem(keyEmailUsuario),
		uid: sessionStorage.getItem(keyUidlUsuario),
	};

	if (!seLogado()) {
		desloga();
	}

	return user;
}

export async function desloga(direciona) {
	var seEstavaLogado = sessionStorage.removeItem(keyEmailUsuario) !== null

	await auth.signOut().then(() => {
		sessionStorage.removeItem(keyEmailUsuario)
		sessionStorage.removeItem(keyUidlUsuario)
		sessionStorage.removeItem(keyPermissao)
		sessionStorage.removeItem(keyExpiracaoTokenLoginUsuario)
		sessionStorage.removeItem(keyInfoUsuarioFoto)

		if (verificarValidadeDeCampo(direciona)) {
			window.location.assign(direciona)
		} else {
			if (seEstavaLogado) {
				window.location.assign('/logout')
			} else {
				window.location.assign('/deslogado')
			}
		}
	});
}

export async function verificaPermissaoNoFirebase(tokenParaTeste) {
	//Essa é a permissão de usuário
	let permissaoAchada = '100';

	await pegarDocumentoFirestore('Permissoes', tokenParaTeste)
		.then((permissoesPurasDoFirebase) => {
			if (verificarValidadeDeCampo(permissoesPurasDoFirebase) &&
				verificarValidadeDeCampo(permissoesPurasDoFirebase.idPermissao)) {
				permissaoAchada = permissoesPurasDoFirebase.idPermissao;
			}
		}).catch((e) => {
			throw triagemDeErrosDoServidor(e, 'Não foi possível encontrar a permissão desse usuário.');
		})

	return permissaoAchada;
}