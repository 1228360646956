import { Router, Switch } from "react-router-dom";
import { Component } from 'react'

//Telas
import PrivateRoute from "../../components/PrivateRoute";
import MeusAnimais from "./telasHome/MeusAnimais";
import InicioHome from "./telasHome/InicioHome";

//Helpers
import { seLogado } from '../../helpers/session'

//Components
import InfoUsuario from './InfoUsuario';
import Drawer from './Drawer';

//CSS
import '../../css/home/home.css'

//History
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

export default class Home extends Component {
	constructor(props) {
		super(props)

		this.verificouInicialHome = false
	}

	verificaInicialHome() {
		if (!seLogado()) {
			window.location.assign('/')
		}
	}

	componentDidMount() {
		if (!this.verificouInicialHome) {
			this.verificouInicialHome = true;
			this.verificaInicialHome()
		}
	}

	render() {
		return (
			<div>
				<div style={{
					height: '40px',
					display: 'grid',
					placeItems: 'center',
					fontSize: '15px',
					fontWeight: '600',
					backgroundColor: 'red',
					color: 'white'
				}}>
					Página em construção. Os cliques e telas foram desativados.
				</div>
				<div className='home'>

					<div className='drawer-home'>
						{
							seLogado() &&
							<InfoUsuario />
						}

						<Drawer />
					</div>
					<div className='body-home'>
						<Router history={history}>
							<Switch>
								<PrivateRoute exact path="/home" component={InicioHome} />
								<PrivateRoute exact path="/home/meusanimais" component={MeusAnimais} />
							</Switch>
						</Router>
					</div>
				</div>
			</div>
		)
	}
}