import '../css/shared/dialogs.css'
import { verificarValidadeDeCampo } from '../helpers/validacoes'

export function dialogMaior(showDialog) {
	return <div className='fundo-dialog-maior'>
		<div className='dialog-maior'>
			{/* ------------------- IMAGEM */}
			<img
				src={require("../components/img/iconFinal.png").default}
				alt=""
				onClick={() =>
					this.setState({ redirect: '/' })
				}
				className="imagem-dialog-maior" />

			{/* ------------------- TÍTULO */}
			{
				verificarValidadeDeCampo(showDialog['titulo']) &&
				<div className='titulo-dialog-maior'>
					{showDialog['titulo']}
				</div>
			}

			{/* ------------------- CORPO */}
			{
				verificarValidadeDeCampo(showDialog['corpo']) &&
				<div className='corpo-dialog-maior'>
					{showDialog['corpo']}
				</div>
			}

			{/* ------------------- BOTÃO */}
			{
				verificarValidadeDeCampo(showDialog['botao']) &&
				verificarValidadeDeCampo(showDialog['clicouBotao']) &&
				<div className='botao-dialog-maior' onClick={showDialog['clicouBotao']}>
					{showDialog['botao']}
				</div>
			}

		</div>
	</div>
}


export function mapaDialog(titulo, corpo, botao, clicouBotao) {
	return {
		"titulo": titulo,
		"corpo": corpo,
		"botao": botao,
		"clicouBotao": clicouBotao
	}
}