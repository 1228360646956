import { initializeApp } from "firebase/app"

export const firebaseConfig = {
	apiKey: "AIzaSyCRbCaTieAE7JpSLuW1kzQwSef_2_pc0L0",
	authDomain: "diskvet-2a6e5.firebaseapp.com",
	databaseURL: "https://diskvet-2a6e5.firebaseio.com",
	projectId: "diskvet-2a6e5",
	storageBucket: "diskvet-2a6e5.appspot.com",
	messagingSenderId: "189859099204",
	appId: "1:189859099204:web:e08172da922185e8304211",
	measurementId: "G-ME9PWF1NF4"
};

export var firebaseApp = initializeApp(firebaseConfig)