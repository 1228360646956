import { seSouEu } from "./permission";
import { verificarValidadeDeCampo } from "./validacoes";

export function triagemDeErrosDoServidor(
	exceptionVindo, casoNaoEncontreQualErro) {
	if (verificarValidadeDeCampo(exceptionVindo)
		&& verificarValidadeDeCampo(exceptionVindo.code)) {
		switch (exceptionVindo.code) {
			// -------------------- LOGIN
			case 'auth/wrong-password':
				return 'Senha incorreta.'
			case 'auth/too-many-requests':
				return 'Muitas tentativas em pouco tempo, por favor tente mais tarde.'
			case 'auth/user-not-found':
				return 'Usuário não encontrado, por favor cadastre-se.'

			// -------------------- DEMAIS
			case 'permission-denied':
				return 'Usuário não tem permissão. Contate o ADM com esta tela para solicitar acesso.';
			case 'unavailable':
				return 'Poderia verificar sua conexão com internet e tentar novamente? Obrigado. Lembre-se de contatar o ADM caso erro persista.';
			case 'failed-precondition':
				return 'Tentou fazer uma operação ainda não permitida. Contate o ADM imediatamente.';
			case 'invalid-argument':
				if (seSouEu()) {
					console.log(exceptionVindo)
				}
				return 'DEV: Há má formação na requisição para o servidor.';
			default:
				if (seSouEu()) {
					console.log(exceptionVindo)
				}
				return casoNaoEncontreQualErro;
		}
	} else {
		return casoNaoEncontreQualErro;
	}
}

