import { Component } from 'react'
import { Redirect } from "react-router-dom";

//CSS
import '../css/pagina-erro-grande.css'

export default class PaginaErroGrande extends Component {
	constructor(props) {
		super(props)

		this.state = {
            redirect: null,
        }

        this.redireciona =
            props.redireciona !== undefined && props.redireciona !== null
                ? props.redireciona
                : null
        this.redirecionaPos =
            props.redirecionaPos !== undefined && props.redirecionaPos !== null && props.redirecionaPos !== ""
                ? props.redirecionaPos
                : null

        this.texto = props.texto
        this.subtexto = props.subtexto
        this.tentativas = 0
	}

	    async componentDidMount() {
        if (this.redireciona !== null) {
            if (this.redirecionaPos !== null) {
                new Promise(resolve => setTimeout(resolve, 2000))
                    .then(() => {
                        this.setState({ redirect: `${this.redireciona}${this.redirecionaPos}` })
                    })
            } else {
                new Promise(resolve => setTimeout(resolve, 2000))
                    .then(() => {
                        this.setState({ redirect: this.redireciona })
                    })
            }
        }
    }

	render() {
		 if (this.state.redirect) {
            return <Redirect push to={this.state.redirect} />
        }

		return (
			<div className='pagina-erro-grande'>
                <div>
                    <img
                        src={require("../components/img/iconFinal.png").default}
						alt=""
                        onClick={() =>
                            this.setState({ redirect: '/' })
                        }
                        className="logo" />
                </div>
                <div className="texto">{this.texto}</div>
                <div className="subtexto">{this.subtexto}</div>
            </div>
		)
	}
}