import React, { Component } from 'react'
import { Redirect } from "react-router-dom";

import { VscClose } from 'react-icons/vsc';

//CSS
import '../css/pagina-erro-pequeno.css'

export class PaginaErroPequeno extends Component {
	constructor(props) {
		super(props)

		this.state = {
			redirect: null,
		}

		this.redireciona =
			props.redireciona !== undefined && props.redireciona !== null
				? props.redireciona
				: null
		this.redirecionaPos =
			props.redirecionaPos !== undefined && props.redirecionaPos !== null && props.redirecionaPos !== ""
				? props.redirecionaPos
				: null

		this.texto = props.texto
	}

	render() {
		if (this.state.redirect) {
			return <Redirect push to={this.state.redirect} />
		}

		return (
			<div className='pagina-erro-pequeno'>
				<div>
					<img
						src={require("../components/img/iconFinal.png").default}
						alt=""
						onClick={() =>
							this.setState({ redirect: '/' })
						}
						className="logo" />
				</div>
				<VscClose className="icon"/>
				<div className="texto">{this.texto}</div>
			</div>
		)
	}
}

export default PaginaErroPequeno
