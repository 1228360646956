import React, { Component } from 'react'

//Helpers
import { verificarValidadeDeCampo } from '../../helpers/validacoes'

//CSS
import '../../css/components/nav-bar.css'

export default class NavBar extends Component {
	constructor(props) {
		super(props)

		this.itensNavBar = [
			{
				id: 0,
				nome: 'Início',
				diretorio: '/',
				click: () => {
					window.location.assign('/')
				}
			},
			{
				id: 1,
				nome: 'Como Funciona',
				diretorio: '/bemvindo/comofunciona',
				click: () => {
					window.location.assign('/bemvindo/comofunciona')
				}
			},
			{
				id: 2,
				nome: 'Quem somos',
				diretorio: '/bemvindo/quemsomos',
				click: () => {
					window.location.assign('/bemvindo/quemsomos')
				}
			}
		]
	}

	render() {
		return (
			<div className="nav-bar">
				<div>
					{
						this.itensNavBar.map((item) => {
							return <span key={item.id} className='item' onClick={
								() => {
									if (verificarValidadeDeCampo(item.click)) {
										item.click()
									}
								}
							}>
								{item.nome}
							</span>
						}
						)
					}
				</div>
			</div>
		)
	}
}
