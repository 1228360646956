import React, { Component } from 'react'

//CSS
import '../../../css/bemVindo/telasBemVindo/como-funciona.css'

export class ComoFunciona extends Component {
	render() {
		return (
			<div className='corpo-como-funciona'>
				<h1><strong>O APLICATIVO</strong></h1>
				<p><strong><em>O que &eacute; a Diskvet App?</em></strong></p>
				<p><em>Diskvet App</em> &eacute; um aplicativo que leva o Veterin&aacute;rio no conforto do seu lar. Tudo isso sem burocracia, sem filas intermin&aacute;veis e sem tempo de espera. Com a <em>Diskvet App</em> o paciente n&atilde;o corre riscos de contrair outras doen&ccedil;as, pois tudo &eacute; feito em sua resid&ecirc;ncia evitando assim doen&ccedil;as cruzadas. O atendimento &eacute; feito onde o cliente desejar e aonde o animal se sentir mais &agrave; vontade.</p>
				<p>&nbsp;</p>
				<p><strong><em>Quem faz parte da equipe Diskvet App?</em></strong></p>
				<p>O aplicativo <em>Diskvet App</em> &eacute; uma ferramenta para o uso de profissionais que possuem registro nos conselhos regionais de medicina veterin&aacute;ria. Al&eacute;m dos m&eacute;dicos veterin&aacute;rios cadastrados que realizam o atendimento, temos uma equipe multidisciplinar composta por prestadores de servi&ccedil;os terceirizados com experi&ecirc;ncias nas suas determinadas &aacute;reas.</p>
				<p>&nbsp;</p>
				<p><strong><em>Como me cadastrar na Diskvet App?</em></strong></p>
				<p>Se voc&ecirc; busca servi&ccedil;os veterin&aacute;rios ou servi&ccedil;os terceirizados relacionados a &aacute;rea pet, basta baixar o aplicativo e efetuar o seu cadastro, isso leva menos de um minuto.</p>
				<p>&nbsp;</p>
				<p><strong><em>O aplicativo &eacute; seguro?</em></strong></p>
				<p>Bastante seguro. A confidencialidade dos seus dados &eacute; um compromisso da <em>Diskvet App</em> e jamais ser&atilde;o comercializados para qualquer que seja a finalidade.<span>&nbsp; </span>Al&eacute;m disso, trabalhamos com os m&eacute;dicos e prestadores de servi&ccedil;os mais capacitados na sua &aacute;rea de atua&ccedil;&atilde;o da regi&atilde;o, garantindo a m&aacute;xima qualidade nos nossos servi&ccedil;os.</p>
				<p>&nbsp;</p>
				<p><strong><em>Quando posso usar o aplicativo?</em></strong></p>
				<p>O aplicativo estar&aacute; dispon&iacute;vel para solicita&ccedil;&atilde;o de consultas 24 horas, mas o atendimento veterin&aacute;rio ser&aacute; de acordo com a disponibilidade dos Veterin&aacute;rios cadastrados no momento da solicita&ccedil;&atilde;o.<span>&nbsp;</span></p>
				<p>&nbsp;</p>
				<p><strong><em>Como pedir um m&eacute;dico veterin&aacute;rio pelo aplicativo?</em></strong></p>
				<p>Muito simples. Basta instalar o aplicativo, informar o endere&ccedil;o onde voc&ecirc; precisa receber um m&eacute;dico e a especialidade desejada.<span>&nbsp;</span></p>
				<p>&nbsp;</p>
				<p><strong><em>Onde posso fazer o download do aplicativo?</em></strong></p>
				<p>O aplicativo est&aacute; dispon&iacute;vel para downloads nas lojas da Apple Store (iOS) e Google Play (Android), de acordo com o modelo do seu aparelho.</p>
				<p>&nbsp;</p>
				<h1><strong>PAGAMENTO</strong></h1>
				<p><strong><em>Qual o valor da consulta?</em></strong></p>
				<p>Os valores variam de acordo com a sua localiza&ccedil;&atilde;o, veterin&aacute;rio e hor&aacute;rio de atendimento. Verificar pelo chat com o profissional no momento da solicita&ccedil;&atilde;o.</p>
				<p>&nbsp;</p>
				<p><strong><em>Como &eacute; realizado o pagamento?</em></strong></p>
				<p>O pagamento &eacute; realizado &agrave; vista, transfer&ecirc;ncia online, PIX ou via cart&atilde;o de cr&eacute;dito diretamente com o profissional solicitado.</p>
				<p>&nbsp;</p>
				<p><strong><em>O pagamento &eacute; seguro?</em></strong></p>
				<p>Sim, nosso sistema de pagamento &eacute; o mesmo utilizado por grandes corpora&ccedil;&otilde;es e garante seguran&ccedil;a em todas as transa&ccedil;&otilde;es financeiras.</p>
				<p>&nbsp;</p>
				<p><strong><em>Tenho direito a reembolso do conv&ecirc;nio?</em></strong></p>
				<p>&Eacute; poss&iacute;vel que sim! Para responder essa pergunta, voc&ecirc; deve consultar seu contrato com seu plano de sa&uacute;de, muitos deles permitem que voc&ecirc; reembolse determinados valores.</p>
			</div>
		)
	}
}

export default ComoFunciona
