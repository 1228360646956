import { firebaseApp } from '../cache/firebase'
import { getFirestore, doc, getDoc } from "firebase/firestore";

const db = getFirestore(firebaseApp);

export async function pegarDocumentoFirestore(colecao, documento) {
	const docRef = doc(db, colecao, documento);
	const docSnap = await getDoc(docRef);

	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		return null;
	}
}