import { Component } from 'react'
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { firebaseApp } from '../../cache/firebase'

//Helpers
import { dialogMaior, mapaDialog } from '../../shared/dialogs';
import { carregandoOficial } from '../../shared/carregador';
import { triagemDeErrosDoServidor } from '../../helpers/erros';

//Functions
import { loga, verificaPermissaoNoFirebase } from '../../helpers/session';
import { xDeFechar } from '../../shared/botoes';

//CSS
import '../../css/login/login-page.css'
import '../../css/shared/botoes.css'
import '../../css/shared/form.css'

export default class LoginPage extends Component {
	constructor(props) {
		super(props)

		this.state = {
			email: "",
			senha: "",
			showDialog: ''
		}

		this.error = '';
	}

	digitandoEmail = event => {
		this.setState({
			email: event.target.value
		})
	}

	digitandoSenha = event => {
		this.setState({
			senha: event.target.value
		})
	}

	isValidEmail() {
		if (this.state.email === "") {
			this.error = "Informe o email"
			return false
		} else if (this.state.email.length > 100) {
			this.error = "Tamanho máximo de 100 caracteres."
			return false
		} else if (!this.state.email.includes('@') || !this.state.email.includes('.co')) {
			this.error = "Digite um e-mail válido"
			return false
		} else {
			this.error = ""
			return true
		}
	}

	isValidSenha() {
		if (this.state.senha === "") {
			this.error = "Informe a senha"
			return false
		} else if (this.state.senha.length > 50) {
			this.error = "Tamanho máximo de 50 caracteres. Pode abreviar"
			return false
		} else {
			this.error = ""
			return true
		}
	}

	_deuErradoLogar(mensagem) {
		this.error = mensagem;
		this.setState({});
	}

	logar() {
		if (this.isValidEmail() && this.isValidSenha()) {
			this.error = null;

			this.setState({})
			const auth = getAuth(firebaseApp);

			signInWithEmailAndPassword(auth, this.state.email, this.state.senha)
				.then((userCredential) => {
					var usuarioAchado = userCredential.user;

					if (usuarioAchado.emailVerified) {
						verificaPermissaoNoFirebase(usuarioAchado.uid)
							.then((permissaoEncontrada) => {
								loga(usuarioAchado, permissaoEncontrada, '/home')
							}).catch((e) => this._deuErradoLogar(e));

					} else {
						sendEmailVerification(usuarioAchado)
							.then(() =>
								this.setState({
									showDialog: mapaDialog('E-mail ainda não confirmado.', 'Entre no seu e-mail e confirme agora mesmo.\n\n Não esqueça de olhar no span, tá?', 'OK!', () => {
										this.setState({
											showDialog: ""
										});
									})
								}))

							.catch((errorEmail) => this._deuErradoLogar(triagemDeErrosDoServidor(errorEmail, 'Não conseguimos enviar e-mail de confirmação, tente novamente.')))
					}
				})
				.catch((errorLogin) => {
					//TODO fazer o bloqueio de tentativa
					this._deuErradoLogar(triagemDeErrosDoServidor(errorLogin, 'Não conseguiu realizar seu login. Tente novamente.'));
				});
		} else {
			this.setState({});
		}
	}

	componentDidMount() {
		if (process.env.NODE_ENV === 'development') {
			this.setState({
				email: process.env.REACT_APP_LOGIN_DEV,
				senha: process.env.REACT_APP_SENHA_DEV,
			})
		}
	}

	render() {
		return (
			<div className='form'>
				{/* ------------------- X DE FECHAR */}
				{xDeFechar('/')}

				{/* ------------------- DIALOG */}
				{
					this.state.showDialog !== "" &&
					dialogMaior(this.state.showDialog)
				}


				{/* ------------------- IMAGEM */}
				<img
					src={require("../../components/img/iconFinal.png").default}
					alt=""
					onClick={() => window.location.assign('/')}
					className="icon-form" />

				{/* ------------------- TÍTULO */}
				<div className='titulo-form'>
					Login
				</div>

				{/* ------------------- CARREGANDO / ERRO (Melhor assim para ocupar o mesmo espaço*/}
				{
					this.error === null
						? carregandoOficial(40)
						: this.error !== ""
						&& <div className={this.error === "" ? 'mensagem-erro' : 'mensagem-erro mostrar-erro'} >
							{this.error}
						</div>
				}

				{/* ------------------- FORM */}
				<form>
					<div className='label-field'>E-mail</div>
					<input className='field' type="text" name="name" value={this.state.email} onChange={this.digitandoEmail} />

					<div className='label-field'>Senha</div>
					<input className='field' type="password" name="name" value={this.state.senha} onChange={this.digitandoSenha} />

					<div className='ao-centro' style={{ marginTop: "15px" }}>
						<div
							className='botao-maior'
							onClick={() => {
								if (this.error !== null) {
									this.logar()
								}
							}}>
							Logar
						</div>
					</div>
				</form>
			</div>
		)
	}
}