import { keyPermissao, listaDrawer } from "./keys";
import { seLogado, usuarioLogado } from "./session";

export function seSouEu() {
	var usuarioChegou = usuarioLogado();

	return usuarioChegou.uid === 'Gcm6DVMfW2cLp63H4SpQBK91lsz1'
		&& usuarioChegou.email === 'noedsonjprn@yahoo.com.br'
		&& process.env.NODE_ENV === 'development';
}

export function permissaoLogada() {
	if (seLogado()) {
		const permissaoSalva = decodificarPermissaoDeTela(sessionStorage.getItem(keyPermissao))

		return mapaDasPermissoes()
			.find((perm) => perm.id === permissaoSalva);
	} else {
		return mapaDasPermissoes().find((element) => element.id === '101');
	}
}

export function mapaDasPermissoes() {
	return [
		{ 'id': '0', 'nome': 'AdminSitema' },
		{ 'id': '1', 'nome': 'Admin' },
		{ 'id': '2', 'nome': 'Veterinário' },
		{ 'id': '3', 'nome': 'Parceiro' },
		{ 'id': '100', 'nome': 'Usuário' },
		{ 'id': '101', 'nome': 'Deslogado' }
	];
}

//Função Codificar
const qtdCasas = 36
const parteFinal = 8
const numeroTop = qtdCasas - parteFinal

export function codificarPermissaoDeTela(idParaTestes) {
	let idVindo = idParaTestes

	if (typeof (idParaTestes) != 'number') {
		idVindo = parseInt(idParaTestes)
	}

	var stringFinal = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var totalSomado = 0
	var totalSubtraido = 0

	const casasSobem = process.env.REACT_APP_CODIFICADOR_PERMISSAO_TELA_S.split('/')
	const casasDescem = process.env.REACT_APP_CODIFICADOR_PERMISSAO_TELA_D.split('/')

	for (var i = 0; i < qtdCasas - parteFinal; i++) {
		var stringAddFinal = undefined

		var numeroRandomico = Math.floor(Math.random() * 10)
		var caractereRandomico = characters.charAt(Math.floor(Math.random() * characters.length));

		if (casasSobem.includes(i.toString())) {
			totalSomado = totalSomado + numeroRandomico
			stringAddFinal = numeroRandomico
		} else if (casasDescem.includes(i.toString())) {
			totalSubtraido = totalSubtraido + numeroRandomico
			stringAddFinal = numeroRandomico
		} else {
			stringAddFinal = caractereRandomico
		}

		stringFinal = stringFinal + stringAddFinal
	}

	var sePositivo = totalSomado - totalSubtraido + idVindo > 0 ? 'p' : 'n'

	var diferencaMaisId = Math.abs((totalSomado - totalSubtraido) + idVindo)

	stringFinal = stringFinal + diferencaMaisId.toString().length + sePositivo + diferencaMaisId

	for (let j = qtdCasas - parteFinal; j <= qtdCasas; j++) {
		stringFinal = stringFinal + characters.charAt(Math.floor(Math.random() * characters.length));
	}

	return stringFinal
}

export function decodificarPermissaoDeTela(id) {
	var stringVinda = id

	var totalSomado = 0
	var totalSubtraido = 0

	const casasSobem = process.env.REACT_APP_CODIFICADOR_PERMISSAO_TELA_S.split('/')
	const casasDescem = process.env.REACT_APP_CODIFICADOR_PERMISSAO_TELA_D.split('/')

	casasSobem.forEach((casaSobe) => {
		totalSomado = totalSomado + parseInt(stringVinda[parseInt(casaSobe)])
	})

	casasDescem.forEach((casaDesce) => {
		totalSubtraido = totalSubtraido + parseInt(stringVinda[parseInt(casaDesce)])
	})

	var sePositivo = stringVinda[numeroTop + 1] === 'p' ? true : false

	var diferencaMaisId = parseInt(stringVinda.substring(numeroTop + 2, numeroTop + 2 + parseInt(stringVinda[numeroTop])))

	var numeroProcurado = 0

	if (sePositivo) {
		numeroProcurado = diferencaMaisId - totalSomado + totalSubtraido
	} else {
		numeroProcurado = (-diferencaMaisId) - totalSomado + totalSubtraido
	}

	return numeroProcurado.toString()
}

export function verificarSePermissaoPodeSerValida(id) {
	var stringVinda = id

	if (stringVinda === null) {
		return false
	} else {
		if (stringVinda.length !== (qtdCasas + 5) && stringVinda.length !== (qtdCasas + 4)) {
			return false
		} else {
			const casasSobem = process.env.REACT_APP_CODIFICADOR_PERMISSAO_TELA_S.split('/')
			const casasDescem = process.env.REACT_APP_CODIFICADOR_PERMISSAO_TELA_D.split('/')

			var numeroInvalido = false
			casasSobem.forEach((casaSobe) => {
				if (numeroInvalido === false && isNaN(parseInt(stringVinda[parseInt(casaSobe)]))) {
					numeroInvalido = true
				}
			})

			casasDescem.forEach((casaDesce) => {
				if (numeroInvalido === false && isNaN(parseInt(stringVinda[parseInt(casaDesce)]))) {
					numeroInvalido = true
				}
			})

			if ((stringVinda[numeroTop + 1] !== 'p' && stringVinda[numeroTop + 1] !== 'n')
				|| numeroInvalido) {
				return false
			} else {
				return true
			}
		}
	}
}

export function mapaDatelaAtual() {
	return listaDrawer.find((itemLista) => itemLista.direciona === window.location.pathname);
}

export function pedirPermissaoDeTela() {
	//É importante pedir essa permissão para o caso de o usuário digitar a url e querer entrar na tela
	return todasAsTelasAutorizadasParaEstePerfil().includes(mapaDatelaAtual())
		&& seLogado();
}

export function todasAsTelasAutorizadasParaEstePerfil() {
	let _todasAutorizadasParaEstePerfil = [];

	listaDrawer.forEach((mapaParaTriagemPerfil) => {
		if (mapaParaTriagemPerfil.perfisAutorizados == null ||
			mapaParaTriagemPerfil.perfisAutorizados
				.includes(permissaoLogada().id)) {
			_todasAutorizadasParaEstePerfil.push(mapaParaTriagemPerfil);
		}

		return null;
	});

	return _todasAutorizadasParaEstePerfil
}