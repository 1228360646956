import React, { Component } from 'react'

//Helpers
import { mapaDatelaAtual, todasAsTelasAutorizadasParaEstePerfil } from '../../helpers/permission';
import { desloga } from '../../helpers/session';
import { verificarValidadeDeCampo } from '../../helpers/validacoes';

//CSS
import '../../css/home/drawer.css'

export class Drawer extends Component {
	render() {
		return (
			<div className='itens-drawer'>
				{todasAsTelasAutorizadasParaEstePerfil().map((itemDrawer) => {
					let classeFinal = 'item-drawer'

					if (mapaDatelaAtual().direciona === itemDrawer.direciona) {
						classeFinal = classeFinal + ' item-drawer-selecionado'
					}

					return <div key={itemDrawer.id} className={classeFinal} onClick={
						() => {
							if (verificarValidadeDeCampo(itemDrawer.direciona) && typeof (itemDrawer.direciona) === 'string') {
								window.location.assign(itemDrawer.direciona)
							}
						}
					}>
						{itemDrawer.nome}
					</div>
				})}

				<div className='botao-maior ajuste-botao'
					style={{ marginTop: '25px', minWidth: '150px', maxWidth: '75%' }}
					onClick={() => desloga()}>
					Logout
				</div>
			</div>
		)
	}
}

export default Drawer