import { Route, Router, Switch } from "react-router-dom";

//Telas
import PrivateRoute from "./components/PrivateRoute";
import BemVindo from "./layouts/bemVindo/BemVindo";
import LoginPage from "./layouts/login/LoginPage";
import Home from "./layouts/home/Home";
import PaginaErroGrande from "./layouts/PaginaErroGrande";

//CSS
import './App.css';

//History
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

export default function Routes() {
	return (
		<Router history={history}>
			<Switch>
				<Route exact path="/" component={BemVindo} />
				<Route path="/bemvindo" component={BemVindo} />
				
				<Route exact path="/login" component={LoginPage} />

				<PrivateRoute path="/home" component={Home} />

				<Route path="/logout" render={() => <PaginaErroGrande texto="Logout com sucesso." subtexto="Esperamos te ver em breve!" redireciona='/' />} />
				<Route path="/deslogado" render={() => <PaginaErroGrande texto="Ops, você foi deslogado..." subtexto="Aguarde que vamos redirecionar..." redireciona='/login' />} />
				<Route path="/sempermissao" render={() => <PaginaErroGrande texto="Parece que não tem permissão" subtexto="Constatamos que não tem permissão de estar aqui. Contate o ADM em caso de dúvida."/>} />
				<Route render={() => <PaginaErroGrande texto="Ops, página não encontrada..." subtexto="Contate o suporte com essa mensagem." />} />
			</Switch>
		</Router>
	);
}