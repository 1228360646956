import { Route, Redirect } from "react-router-dom";
import React from "react";
import { seLogado } from "../helpers/session";
import { pedirPermissaoDeTela } from "../helpers/permission";

const PrivateRoute = props => {
    return seLogado()
        ? pedirPermissaoDeTela()
            ? <Route {...props} />
            : <Redirect to='/sempermissao/permissaonaoconcedida' />
        : <Redirect to={`/deslogado`}/>
}

export default PrivateRoute