import React, { Component } from 'react'
import { AiOutlineUser } from 'react-icons/ai';

//Helpers
import { seLogado } from '../../../helpers/session'

//CSS
import '../../../css/bemVindo/telasBemVindo/inicio-bem-vindo.css'
import '../../../css/shared/botoes.css'
import '../../../css/home/info-usuario.css'

export class InicioBemVindo extends Component {
	render() {
		return (
			<div class-class='inicio-bem-vindo'>
				{/* ------------------- CORPO */}
				<div className='titulo'>
					Seja bem-vindo ao DiskVetApp
				</div>

				<div className='sub-titulo'>
					O melhor app de atendimento veterinário em casa!
				</div>

				{/* ------------------- CORPO QR CODE E BOTÃO */}
				<div className='corpo-qrcode-botao'>
					{
						!seLogado() &&
						<div>
							<div>
								<AiOutlineUser className='icon-foto-usuario' />
							</div>
							<div className='botao-maior ajuste-botao'
								onClick={() => window.location.assign('/login')}>
								Logar
							</div>
						</div>
					}
					<div className='ou'>
						<div className='divider-ou' />
						Ou
						<div className='divider-ou' />
					</div>
					<div className='baixe-agora-mesmo'>
						Baixar agora mesmo!
						<div>
							<img
								src={require("../../../components/img/diskvetAppQRCode.png").default}
								alt=""
								onClick={() => window.location.assign('/')
								}
								className="qr-code" />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default InicioBemVindo
