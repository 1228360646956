import { verificarValidadeDeCampo } from "./validacoes";

export function dataDepoisDeAgora(dataVinda) {
	if(verificarValidadeDeCampo(dataVinda)) {

	const deMillisegundosParaHora = (60 * 60 * 1000)
	const offsetTimeZone = 3 * deMillisegundosParaHora;

	let dataAgoraMilisegundosUTC = Date.now();
	let dataAgoraMilisegundosLocal = dataAgoraMilisegundosUTC - offsetTimeZone;

	return dataVinda > dataAgoraMilisegundosLocal 
	} else {
		throw Error(`DEV: Tentou calcular uma data inválida: ${dataVinda}`)
	}
}