import React, { Component } from 'react'

//Functions
import { pegarDocumentoFirestore } from '../../functions/firestore';

//Helpers
import { verificarValidadeDeCampo } from '../../helpers/validacoes';
import { usuarioLogado } from '../../helpers/session'
import { triagemDeErrosDoServidor } from '../../helpers/erros';
import { keyInfoUsuarioFoto } from '../../helpers/keys';
import { permissaoLogada, seSouEu } from '../../helpers/permission';

//Components
import { AiOutlineUser } from 'react-icons/ai';
import { carregandoOficial } from '../../shared/carregador';

//CSS
import '../../css/home/info-usuario.css'

export class InfoUsuario extends Component {
	render() {
		return (
			<div className='info-usuario'>
				<FotoUsuario />
				<div className='texto-info-usuario'>
					Olá, {usuarioLogado().email}.
				</div>
				{
					seSouEu() &&
					<div style={{ fontWeight: '500' }}>
						Logado como {permissaoLogada().nome}
					</div>
				}


			</div>
		)
	}
}

export class FotoUsuario extends Component {
	constructor(props) {
		super(props)

		this.state = {
			stringSeErroFotoUsuario: null,
			urlFoto: null
		}

		this.mandouPegarDadosUsuario = false;
		this.keyDaqui = keyInfoUsuarioFoto
	}

	deuCerto(dataDeuCerto) {
		sessionStorage.setItem(this.keyDaqui, dataDeuCerto)

		this.setState({
			urlFoto: dataDeuCerto,
			stringSeErroFotoUsuario: ""
		})
	}

	deuErrado(mensagem) {
		this.setState({
			stringSeErroFotoUsuario: mensagem
		})
	}

	async pegarDadosUsuario() {

		if (verificarValidadeDeCampo(sessionStorage.getItem(this.keyDaqui))) {
			this.deuCerto(sessionStorage.getItem(this.keyDaqui))
		} else {
			await pegarDocumentoFirestore('InfoUsuario', usuarioLogado().uid).then((v) => {
				const diretorioImg = v.imgUsuario
				if (verificarValidadeDeCampo(diretorioImg)) {
					this.deuCerto(diretorioImg)
				}
			}).catch((e) => {
				this.deuErrado(triagemDeErrosDoServidor(e, 'Não conseguiu pegar seus dados. Tente novamente.'))
			})
		}
	}

	componentDidMount() {
		if (!this.mandouPegarDadosUsuario) {
			this.mandouPegarDadosUsuario = true;
			this.pegarDadosUsuario()
		}
	}

	render() {
		if (this.state.stringSeErroFotoUsuario == null) {
			return carregandoOficial(30)
		}

		if (this.state.stringSeErroFotoUsuario !== '') {
			return <div className='erro-carregamento-foto-usuario'>
				{this.state.stringSeErroFotoUsuario}
			</div>
		}

		if (verificarValidadeDeCampo(this.state.urlFoto)) {
			return <img alt='' src={this.state.urlFoto} className='foto-usuario' />

		}

		return (
			<div>
				<AiOutlineUser className='icon-foto-usuario' />
			</div>
		)
	}
}

export default InfoUsuario
