import { Route, Router, Switch } from 'react-router'
import { Component } from 'react'

//Telas
import NavBar from './NavBar'
import QuemSomos from './telasBemVindo/QuemSomos'
import InicioBemVindo from './telasBemVindo/InicioBemVindo';
import ComoFunciona from './telasBemVindo/ComoFunciona';

//CSS
import '../../css/bemVindo/bem-vindo.css'

//History
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

export default class BemVindo extends Component {
	render() {
		return (
			<div className='bem-vindo maxWidth'>
				<img
					src={require("../../components/img/banner1.jpeg").default}
					alt=""
					onClick={() => window.location.assign('/')
					}
					className="banner" />

				<NavBar />

				<div className='corpo'>
					<Router history={history}>
						<Switch>
							<Route exact path="/" component={InicioBemVindo} />
							<Route exact path="/bemvindo/comofunciona" component={ComoFunciona} />
							<Route exact path="/bemvindo/quemsomos" component={QuemSomos} />
						</Switch>
					</Router>
				</div>
			</div>
		)
	}
}
