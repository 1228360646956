import React, { Component } from 'react'

//Helpers
// import { pegarDocumentoFirestore } from '../../../functions/firestore'
// import { triagemDeErrosDoServidor } from '../../../helpers/erros'
// import { verificarValidadeDeCampo } from '../../../helpers/validacoes'
import { carregandoOficial } from '../../../shared/carregador'
import PaginaErroPequeno from '../../PaginaErroPequeno'

//CSS
import '../../../css/bemVindo/telasBemVindo/quem-somos.css'

export class QuemSomos extends Component {
	constructor(props) {
		super(props)

		this.state = {
			mapaDoQuemSomos: '',
			stringSeErroNoBackEnd: '',
		}

		this.mandouPegarDadosBemVindo = false;
	}

	// deuCerto(dataDeuCerto) {
	// 	this.setState({
	// 		mapaDoQuemSomos: dataDeuCerto,
	// 		stringSeErroNoBackEnd: ""
	// 	})
	// }

	// deuErrado(mensagem) {
	// 	this.setState({
	// 		stringSeErroNoBackEnd: mensagem
	// 	})
	// }

	// async pegarDadosQuemSomos() {
	// 	await pegarDocumentoFirestore('RenatoPodeAlterar', 'SaibaMais').then((v) => {
	// 		if (verificarValidadeDeCampo(v)) {
	// 			this.deuCerto(v)
	// 		}
	// 	}).catch((e) => {
	// 		this.deuErrado(triagemDeErrosDoServidor(e, 'Não conseguiu pegar dados dessa tela. Tente novamente.'))
	// 	})
	// }

	// componentDidMount() {
	// 	if (!this.mandouPegarDadosBemVindo) {
	// 		this.mandouPegarDadosBemVindo = true;
	// 		this.pegarDadosQuemSomos()
	// 	}
	// }

	render() {
		if (this.state.stringSeErroNoBackEnd == null) {
			return carregandoOficial(30)
		}

		if (this.state.stringSeErroNoBackEnd !== '') {
			return <PaginaErroPequeno texto={this.state.stringSeErroNoBackEnd} />
		}

		return (
			<div className='corpo-quem-somos'>
				<p>A <em>Diskvet app</em> &eacute; um aplicativo que leva o Veterin&aacute;rio no conforto do seu lar. Tudo isso <strong>sem burocracia</strong>, <strong>sem filas intermin&aacute;veis</strong> e <strong>sem tempo de espera</strong>. N&atilde;o perca mais tempo em cl&iacute;nicas, hospitais ou esperando uma consulta com um especialista. <br /><br />Voc&ecirc; entra no aplicativo, escolhe a especialidade m&eacute;dica veterin&aacute;ria desejada e faz o agendamento com os melhores profissionais dispon&iacute;veis na sua redondeza. <br /><br />Com a <em>Diskvet app</em> voc&ecirc; tamb&eacute;m pode solicitar servi&ccedil;os como: <strong>aplica&ccedil;&atilde;o de vacinas</strong>, <strong>microchipagem</strong>, <strong>GTA</strong> (guia de tr&acirc;nsito animal), <strong>ultrassonografia</strong>, al&eacute;m de servi&ccedil;os terceirizados de <strong>banho e tosa</strong>, <strong>adestramento</strong>,<strong> taxi dog</strong> e <strong>dog walking</strong> sem sair de casa, com a comodidade que voc&ecirc; e seu animalzinho merecem.</p>
				<p>&nbsp;</p>
				<p>Contatos: (84) 9 8805-6053</p>
				<p>Instagram: @diskvetapp</p>
				<p>Facebook: Renato DiskVet App</p>
				<p>E-mail: faleconosco@diskvetapp.com.br</p>
			</div>
		)
	}
}

export default QuemSomos
