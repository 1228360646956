//Login
export const keyEmailUsuario = 'emailUsuarioLogado'
export const keyUidlUsuario = 'uidUsuarioLogado'
export const keyExpiracaoTokenLoginUsuario = 'expiration'
export const keyPermissao = 'permission'

//InfoUsuario
export const keyInfoUsuarioFoto = 'infoUsuario_foto'

//InfoGeral
export const keyServicosDisponiveis = 'servicosDisponiveis'

//Drawer
export const listaDrawer = [
			{
				'id': 0,
				'nome': 'Início',
				'font': 35.0,
				'direciona': '/home',
				'perfisAutorizados': null,
			},
			{
				'id': 1,
				'nome': 'Meus animais',
				'font': 35.0,
				'direciona': '/home/meusanimais',
				'perfisAutorizados': ['0', '100']
			},
			{
				'id': 2,
				'nome': 'Endereços',
				'font': 35.0,
				'direciona': 12,
				'perfisAutorizados': null
			},
			{
				'id': 3,
				'nome': 'Lembretes',
				'font': 35.0,
				'direciona': 1,
				'perfisAutorizados': null
			},
			{
				'id': 4,
				'nome': 'Permissões',
				'font': 35.0,
				'direciona': 300,
				'perfisAutorizados': ['0', '1']
			},
			{
				'id': 5,
				'nome': 'Solicitações',
				'font': 35.0,
				'direciona': 30,
				'perfisAutorizados': null
			},
			{
				'id': 6,
				'nome': 'Relatórios',
				'font': 35.0,
				'direciona': 40,
				'perfisAutorizados': ['0', '1', '2']
			},
			{
				'id': 7,
				'nome': 'Urgência',
				'font': 35.0,
				'direciona': 26,
				'perfisAutorizados': ['0', '100']
			},
			{
				'id': 8,
				'nome': 'Perfil',
				'font': 35.0,
				'direciona': 50,
				'perfisAutorizados': null
			},
			{
				'id': 9,
				'nome': 'Parceiros',
				'font': 35.0,
				'direciona': 100,
				'perfisAutorizados': null
			}
		];