import React, { Component } from 'react'

//Helpers
import { pegarDocumentoFirestore } from '../../../functions/firestore';
import { triagemDeErrosDoServidor } from '../../../helpers/erros';
import { keyServicosDisponiveis } from '../../../helpers/keys';
import { verificarValidadeDeCampo } from '../../../helpers/validacoes';

//CSS
import '../../../css/home/telasHome/inicio-home.css'
import { carregandoOficial } from '../../../shared/carregador';
import PaginaErroPequeno from '../../PaginaErroPequeno';

export class InicioHome extends Component {
	constructor(props) {
		super(props)

		this.state = {
			mapaDosServicos: null,
			stringSeErroNosServicos: null,
		}

		this.mandouPegarDadosInicioHome = false;
		this.keyDaqui = keyServicosDisponiveis
	}

	deuCerto(dataDeuCerto) {
		sessionStorage.setItem(this.keyDaqui, dataDeuCerto)

		this.setState({
			mapaDosServicos: dataDeuCerto,
			stringSeErroNosServicos: ""
		})
	}

	deuErrado(mensagem) {
		this.setState({
			stringSeErroNosServicos: mensagem
		})
	}

	async pegarDadosInicioHome() {
		if (verificarValidadeDeCampo(sessionStorage.getItem(this.keyDaqui))) {
			this.deuCerto(sessionStorage.getItem(this.keyDaqui))
		} else {
			await pegarDocumentoFirestore('RenatoPodeAlterar', 'ServicosDisponiveis').then((v) => {
				const retirarTodos = v.todos
				const dadosServicos = JSON.stringify(retirarTodos)

				if (verificarValidadeDeCampo(dadosServicos)) {
					this.deuCerto(dadosServicos)
				}
			}).catch((e) => {
				this.deuErrado(triagemDeErrosDoServidor(e, 'Não conseguiu pegar dados dessa tela. Tente novamente.'))
			})
		}
	}

	componentDidMount() {
		if (!this.mandouPegarDadosInicioHome) {
			this.mandouPegarDadosInicioHome = true;
			this.pegarDadosInicioHome()
		}
	}


	render() {
		var deStringParaMapa
		if (verificarValidadeDeCampo(this.state.mapaDosServicos)) {
			deStringParaMapa = JSON.parse(this.state.mapaDosServicos)
		}

		return (
			<div className='inicio-home'>
				<div>
					{/* ------------------- IMAGEM */}
					<img
						src={require("../../../components/img/banner2.jpeg").default}
						alt=""
						onClick={() => window.location.assign('/')
						}
						className="banner-inicio-home" />

					{/* ------------------- TEXTO */}
					<div className='titulo-inicio-home'>
						Olá
					</div>

					<div className='subtitulo-inicio-home'>
						O que podemos fazer hoje pelo seu amiguinho?
					</div>

					{/* ------------------- SERVIÇOS */}
					{
						this.state.stringSeErroNosServicos == null
							? carregandoOficial(30)
							: this.state.stringSeErroNosServicos !== ''
								? <PaginaErroPequeno texto={this.state.stringSeErroNosServicos} />
								: <div className='corpo-servicos'>
									{
										verificarValidadeDeCampo(deStringParaMapa) &&
										deStringParaMapa.map((mapaDoServicoDisponivel) => {
											return <div key={deStringParaMapa.indexOf(mapaDoServicoDisponivel)}
												className='cada-servico'>
												{mapaDoServicoDisponivel.nome}
											</div>
										})
									}
								</div>
					}
				</div>
			</div>
		)
	}
}

export default InicioHome
